import React, { useState, useEffect } from 'react';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import MobileTopBar from '../Navbar/MobileTopBar';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HiChevronRight } from 'react-icons/hi';
import type { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { GET_RESUME_BY_ID } from '../../graphql/queries/getResumeById';
import { useApolloClient, useMutation } from '@apollo/client';
import { EditIcon } from '@chakra-ui/icons';
import { useDebouncedCallback } from 'use-debounce';
import { UPDATE_RESUME } from 'src/graphql/mutations';
import CreateCVModal from '../CreateCVModal/CreateCVModal';
import NavbarWrapper from '../Navbar/NavbarWrapper';

const UserBreadcrumb: BreadcrumbComponentType<'id' | 'letterId'> = ({
  match,
}) => {
  const apollo = useApolloClient();
  const [name, setName] = useState();
  const [letterName] = useState();
  const [updateResume] = useMutation(UPDATE_RESUME);
  const debouncedUpdateCVData = useDebouncedCallback(async (title: string) => {
    await updateResume({ variables: { id: match.params.id, title } });
  }, 1500);
  const handleUpdateCVData: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    debouncedUpdateCVData(value.trim());
  };

  useEffect(() => {
    const getName = async () => {
      const cvName = await apollo
        .query({ query: GET_RESUME_BY_ID, variables: { id: match.params.id } })
        .then((res) =>
          res.data.findOneResume ? res.data.findOneResume.title : null,
        )
        .catch(() => null);

      setName(cvName);
    };
    getName();
  }, [apollo, match.params.id, setName]);

  // useEffect(() => {
  //   const getName = async () => {
  //     const cvName = await apollo
  //       .query({ query: GET_RESUME_BY_ID, variables: { id: match.params.id } })
  //       .then((res) =>
  //         res.data.findOneResume ? res.data.findOneResume.title : null,
  //       )
  //       .catch(() => null);

  //     setName(cvName);
  //   };
  //   getName();
  // }, [apollo, match.params.id, setName]);

  // useEffect(() => {
  //   const getLetterName = async () => {
  //     const name = await apollo
  //       .query({
  //         query: GET_RECOMMENDATION,
  //         variables: { id: match.params.letterId },
  //       })
  //       .then((res) =>
  //         res.data.findOneRecommendation
  //           ? res.data.findOneRecommendation.title
  //           : null,
  //       )
  //       .catch(() => null);

  //     setLetterName(name);
  //   };
  //   if (match.params.letterId) getLetterName();
  // }, [apollo, match.params.letterId, setLetterName]);

  return (
    <div id="change-CV-name">
      {name && !match.params.letterId ? (
        <FormControl display="flex" width="fit-content" alignItems="center">
          <FormLabel
            style={{
              marginBottom: '.25em',
              marginRight: '.5em',
            }}
            cursor="text"
            opacity={'.4'}
            _hover={{ opacity: '1' }}
          >
            <EditIcon w={3} h={3} />
          </FormLabel>
          <Input
            fontWeight="semibold"
            fontSize="sm"
            variant="unstyled"
            placeholder="Enter title here..."
            defaultValue={name}
            onChange={handleUpdateCVData}
            name="title"
            autoFocus
          />
        </FormControl>
      ) : match.params.letterId ? (
        letterName
      ) : (
        match.params.id
      )}
    </div>
  );
};

const Layout: React.FC = React.memo(() => {
  const breadcrumbs = useBreadcrumbs([
    {
      path: '/cv/:id',
      breadcrumb: UserBreadcrumb,
    },
    // {
    //   path: '/cv/:id/recommendation_letters/:letterId',
    //   breadcrumb: UserBreadcrumb,
    // },
  ]);
  const { pathname } = useLocation();

  return (
    <Flex h="100vh" flexDirection="column" w={'full'} pos="relative">
      <MobileTopBar isCVPage={pathname.includes('/cv/')} />
      <Box
        display="flex"
        gap={0}
        w="full"
        h={{ base: 'calc(100vh - 57px)', lg: '100vh' }}
      >
        <Box display={{ base: 'none', lg: 'flex' }}>
          <NavbarWrapper />
        </Box>
        <Flex
          // p={{ base: 0, lg: 6 }}
          pl={{ base: 0, lg: 20 }}
          flexGrow={1}
          maxW="8xl"
          mx="auto"
        >
          <Box
            w={{ base: '100vw', lg: 'calc(100vw - 104px)' }}
            h="full"
            p="1em"
            overflowY="auto"
            overflowX="hidden"
            pos="relative"
            css={{
              '&::-webkit-scrollbar': {
                width: '14px',
              },
              '&::-webkit-scrollbar-track': {
                width: '14px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(26,30,40,0.5)',
                border: '4px solid white',
                borderRadius: '16px',
              },
            }}
          >
            {!pathname.startsWith('/confirm-data/') && (
              <Box pb="4">
                <Breadcrumb
                  // maxW="7xl"
                  // w="full"
                  fontSize="sm"
                  fontWeight="semibold"
                  separator={
                    <Box as={HiChevronRight} color="gray.500" fontSize="lg" />
                  }
                >
                  {breadcrumbs.map(({ breadcrumb, match }) => {
                    return (
                      <BreadcrumbItem key={match.pathname}>
                        <Link to={match.pathname} color={'blue.600'}>
                          {breadcrumb}
                        </Link>
                      </BreadcrumbItem>
                    );
                  })}
                </Breadcrumb>
              </Box>
            )}
            <Outlet />
          </Box>
        </Flex>
      </Box>
      <CreateCVModal />
    </Flex>
  );
});

export default Layout;
