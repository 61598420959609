import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './locales/resources';

export const defaultNS = 'main';

i18next.use(LanguageDetector).use(initReactI18next).use(HttpApi).init({
  debug: true,
  fallbackLng: 'en',
  defaultNS,
  resources,
});

export default i18next;
