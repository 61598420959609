import { useMutation } from '@apollo/client';
import { CREATE_RESUME } from '../graphql/mutations/createCV';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';

export const useCreateResume = () => {
  const [createResumeMutation, { loading, data }] = useMutation(CREATE_RESUME);
  const navigation = useNavigate();

  const createResume = async (
    values?: FormikValues,
    formikActions?: FormikHelpers<FormikValues>,
  ) => {
    if (!!formikActions) {
      formikActions.setSubmitting(false);
      formikActions.resetForm();
    }
    const res = await createResumeMutation({ variables: { ...values } });
    await navigation(`/cv/${res.data.createResume.id}`);
  };

  return { createResumeMutation, createResume, loading, data };
};
