import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  TAppSlice,
  TCoverLettersSlice,
  TResumesSlice,
  createAppSlice,
  createCoverLettersSlice,
  createResumesSlice,
  createApplicationsSlice,
  TApplicationsSlice,
} from './slices';

const useAppStore = create<
  TAppSlice & TCoverLettersSlice & TResumesSlice & TApplicationsSlice
>()(
  devtools(
    persist(
      (...a) => ({
        ...createAppSlice(...a),
        ...createCoverLettersSlice(...a),
        ...createResumesSlice(...a),
        ...createApplicationsSlice(...a),
      }),
      { name: 'appStore' },
    ),
  ),
);

export default useAppStore;
