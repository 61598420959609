import React from 'react';
import { As, Box, BoxProps, HStack, Icon, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useColorModeValue as mode } from '@chakra-ui/react';

interface IProps extends BoxProps {
  icon: As;
  isExternal?: boolean;
}

const NavItem: React.FC<IProps> = React.memo(
  ({ icon, isExternal, ...props }) => {
    return (
      <Box
        px="2"
        py="1.5"
        borderRadius="md"
        _hover={{ bg: 'whiteAlpha.400' }}
        _activeLink={{
          bg: 'gray.700',
          color: 'white',
        }}
        cursor={'pointer'}
        {...props}
      >
        <HStack justify="space-between">
          <HStack spacing="3">
            <Icon as={icon} />
            <Text as="span" fontSize="sm" lineHeight="1.25rem">
              {props.children}
            </Text>
          </HStack>
          {isExternal && (
            <Icon
              as={ExternalLinkIcon}
              boxSize="4"
              color={mode('gray.600', 'gray.400')}
            />
          )}
        </HStack>
      </Box>
    );
  },
);

export default NavItem;
