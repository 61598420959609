import { gql } from '@apollo/client';

export const FIND_ALL_RESUMES = gql`
  query findAllResumes(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $query: String
    $showPublished: Boolean
  ) {
    findAllResumes(
      first: $first
      after: $after
      last: $last
      before: $before
      query: $query
      showPublished: $showPublished
    ) {
      edges {
        node {
          id
          title
          createdAt
          published
          updatedAt
          profile {
            id
            name
          }
          author {
            role
            firstname
            lastname
            email
          }
          industry
          company
          job
          language
          isDefault
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
