import React from 'react';

import { Flex, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  Item,
  AutoCompleteProps,
} from '@choc-ui/chakra-autocomplete';
import { PlusSquareIcon } from '@chakra-ui/icons';

type Props = Omit<AutoCompleteProps, 'children'> & {
  label?: string;
  // value: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  onSelectOption?:
    | ((params: {
        item: Item;
        selectMethod: 'mouse' | 'keyboard' | null;
        isNewInput?: boolean | undefined;
      }) => boolean | void)
    | undefined;
  options: { name: string; value: string; id: string }[];
  helperText?: string;
  // isLoading?: boolean;
  // multiple?: boolean;
  hasAddItem?: boolean;
};

export const AutoCompleteField: React.FC<Props> = ({
  label,
  // value,
  handleChange,
  options,
  helperText,
  onSelectOption,
  hasAddItem,
  ...otherProps
}) => {
  return (
    <Flex justify="center" align="center" w="full">
      <FormControl>
        {label && <FormLabel>{label}</FormLabel>}
        <AutoComplete
          openOnFocus
          onSelectOption={onSelectOption}
          listAllValuesOnFocus
          {...otherProps}
        >
          <AutoCompleteInput
            variant="filled"
            onChange={handleChange}
            size="sm"
          />
          <AutoCompleteList>
            {hasAddItem ? (
              <AutoCompleteItem value="new">
                <PlusSquareIcon mx="auto" />
              </AutoCompleteItem>
            ) : null}
            {options.map(({ id, name, value }) => (
              <AutoCompleteItem
                key={id}
                value={id}
                label={value}
                textTransform="capitalize"
                fontSize="sm"
              >
                {name}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </AutoComplete>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Flex>
  );
};
