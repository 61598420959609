import React from 'react';

import 'driver.js/dist/driver.css';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
// import TableContent from '../../components/MyCVsPage/TableContent';
import TablePagination from '../../components/MyCVsPage/TablePagination';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { FIND_ALL_COVER_LETTERS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import TableContent from './TableContent';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks/useAppToast';
import { CREATE_COVER_LETTER } from 'src/graphql/mutations';

const CoverLettersPage: React.FC = () => {
  const { t } = useTranslation('coverLetters');
  const nav = useNavigate();
  const { showToast, closeToast } = useAppToast('coverLetters');
  const { data, loading, refetch } = useQuery(FIND_ALL_COVER_LETTERS, {
    variables: { query: '', first: 6 },
    fetchPolicy: 'cache-and-network',
  });
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };

  const [createCoverLetter] = useMutation(CREATE_COVER_LETTER, {
    onCompleted: async (data) => {
      await closeToast();
      if (data.createCoverLetter) {
        nav(`/cover-letters/${data.createCoverLetter.id}`);
      }
    },
    onError,
  });

  const handleCreateCoverLetter = async () => {
    showToast({
      content: 'Create cover letter in progress',
      status: 'loading',
      duration: null,
    });
    await createCoverLetter();
  };

  return (
    <Box as="section" py="1">
      <Box maxW={{ base: 'xl', md: '7xl' }}>
        <Box w={'100%'}>
          <Flex justifyContent="space-between">
            <Heading size="lg" mb="6">
              {t('title')}
            </Heading>
            <Button onClick={handleCreateCoverLetter}>
              Create new Cover Letter
            </Button>
          </Flex>
          <TableContent
            data={data?.findAllCoverLetters?.edges}
            refetch={refetch}
            loading={loading}
          />
          <TablePagination
            hasPreviousPage={
              data?.findAllCoverLetters?.pageInfo?.hasPreviousPage
            }
            hasNextPage={data?.findAllCoverLetters?.pageInfo?.hasNextPage}
            total={data?.findAllCoverLetters?.totalCount}
            countString={`${data?.findAllCoverLetters?.totalCount ?? 0} ${t(
              'title',
            )}`}
            startCursor={data?.findAllCoverLetters?.pageInfo?.startCursor}
            endCursor={data?.findAllCoverLetters?.pageInfo?.endCursor}
            loading={loading}
            refetch={refetch}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CoverLettersPage;
