import React from 'react';

import {
  Stack,
  Button,
  FormLabel,
  FormControl,
  Input,
  Textarea,
} from '@chakra-ui/react';
import AppModal from 'src/components/AppModal/AppModal';
import { useTranslation } from 'react-i18next';
import { IApplication } from 'src/types/types';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  application: Partial<IApplication>;
  //   onChange: React.ChangeEventHandler;
};

const CreateApplicationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  application,
  //   onChange,
}) => {
  const { t } = useTranslation('applications');
  const { t: commonT } = useTranslation('common');
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    console.log('searchParams', searchParams.get('id'));
  }, [searchParams]);

  return (
    <AppModal
      title="Fill Application data"
      isOpen={isOpen}
      onClose={onClose}
      //   actions={
      //     <Button
      //       id="save-profile"
      //       type="submit"
      //       colorScheme="green"
      //       isLoading={isLoading}
      //       onClick={onSubmit}
      //     >
      //       {commonT('save')}
      //     </Button>
      //   }
    >
      <Formik<Partial<IApplication>>
        initialValues={application}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
          }, 1000);
        }}
      >
        {({ values: { url, company, title, description }, handleChange }) => (
          <Stack as={Form} gap={2}>
            <FormControl id="url">
              <FormLabel>{t('modal.url.label')}</FormLabel>
              <Input
                defaultValue={url}
                onChange={handleChange}
                placeholder={t('modal.url.placeholder')}
                name="url"
              />
            </FormControl>
            <FormControl id="company" isRequired>
              <FormLabel>{t('modal.company.label')}</FormLabel>
              <Input
                defaultValue={company}
                onChange={handleChange}
                placeholder={t('modal.company.placeholder')}
                name="company"
              />
            </FormControl>
            <FormControl id="title">
              <FormLabel>{t('modal.title.label')}</FormLabel>
              <Input
                defaultValue={title}
                onChange={handleChange}
                placeholder={t('modal.title.placeholder')}
                name="title"
              />
            </FormControl>
            <FormControl id="decription">
              <FormLabel>{t('modal.description.label')}</FormLabel>
              <Textarea
                rows={10}
                defaultValue={description}
                onChange={handleChange}
                placeholder={t('modal.description.placeholder')}
                name="description"
              />
            </FormControl>
            <Button
              id="save-profile"
              type="submit"
              colorScheme="green"
              isLoading={isLoading}
              //   onClick={onSubmit}
            >
              {commonT('save')}
            </Button>
          </Stack>
        )}
      </Formik>
    </AppModal>
  );
};

export default React.memo(CreateApplicationModal);
