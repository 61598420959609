import { TemplateCardProps } from '../components/TemplateCard/TemplateCard';

export const templates: TemplateCardProps[] = [
    {
      id: 'template1',
      name: 'Template 1',
      img: '/TemplateImages/template1.jpg',
    },
    {
      id: 'template2',
      name: 'Template 2',
      img: '/TemplateImages/template2.jpg',
    },
    {
      id: 'template3',
      name: 'Template 3',
      img: '/TemplateImages/template3.jpg',
    },
    {
      id: 'template4',
      name: 'Template 4',
      img: '/TemplateImages/template4.jpg',
    },
    {
      id: 'template5',
      name: 'Template 5',
      img: '/TemplateImages/template5.jpg',
    },
    {
      id: 'template6',
      name: 'Template 6',
      img: '/TemplateImages/template6.jpg',
    },
    {
      id: 'template7',
      name: 'Template 7',
      img: '/TemplateImages/template7.jpg',
    },
    {
      id: 'template8',
      name: 'Template 8',
      img: '/TemplateImages/template8.jpg',
    },
    {
      id: 'template9',
      name: 'Template 9',
      img: '/TemplateImages/template9.jpg',
    },
    {
      id: 'template10',
      name: 'Template 10',
      img: '/TemplateImages/template10.jpg',
    },
    {
      id: 'template11',
      name: 'Template 11',
      img: '/TemplateImages/template11.jpg',
    },
    {
      id: 'template12',
      name: 'Template 12',
      img: '/TemplateImages/template12.jpg',
    },
    {
      id: 'template13',
      name: 'Template 13',
      img: '/TemplateImages/template13.jpg',
    }
  ];