// import { ECVLanguage } from 'src/types/types';
import { StateCreator } from 'zustand';
import { TResumesSlice } from './types';
import { fetchResumes } from './api';

export const createResumesSlice: StateCreator<TResumesSlice> = (set, get) => ({
  initResumes: async () => {
    const resumes = await fetchResumes();
    if (resumes) {
      set({ resumes });
    }
  },
  resumes: [],
  setResumes: (value) => set({ resumes: value }),
});
