import { StateCreator } from 'zustand';
import { TApplicationsSlice } from './types';
import { fetchApplications } from './api';

export const createApplicationsSlice: StateCreator<TApplicationsSlice> = (
  set,
  get,
) => ({
  initApplications: async () => {
    const applications = await fetchApplications();
    if (applications) {
      set({ applications });
    }
  },
  applications: [],
  setApplications: (value) => set({ applications: value }),
});
