import React from 'react';
import { As, BoxProps } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import NavItem from './NavItem';

interface IProps extends BoxProps {
  icon: As;
  to?: string;
  isExternal?: boolean;
}

const NavLink: React.FC<IProps> = React.memo(
  ({ icon, to, isExternal, ...props }) => {
    return (
      <RouterLink to={to || '/'}>
        <NavItem icon={icon} isExternal={isExternal} {...props}>
          {props.children}
        </NavItem>
      </RouterLink>
    );
  },
);

export default NavLink;
