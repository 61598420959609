import React from 'react';

import {
  Flex,
  Heading,
  Button,
  Box,
  Spinner,
  ButtonGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ApplicationsTable from './ApplicationsTable';
import ParseUrlsModal from './ParseUrlsModal';
import { useApplications } from 'src/hooks';
import CreateApplicationModal from './CreateApplicationModal';
import { useSearchParams } from 'react-router-dom';

const ApplicationsPage = () => {
  const { t } = useTranslation('applications');
  const [, setSearchParams] = useSearchParams();
  const {
    onMultipleOpen,
    onOpen,
    loading,
    onApplicationEdit,
    handleDeleteApplication,
    onCoverLetterSelect,
    onResumeSelect,
    handleStatusUpdate,
    isOpen,
    onClose,
    createApplicationLoading,
    updateApplicationLoading,
    handleSubmit,
    onMultipleClose,
    isMultipleOpen,
    application,
    handleCreateApplications,
  } = useApplications();

  return (
    <Box as="section" py="1">
      <Box maxW="7xl">
        <Box w={'100%'}>
          <Flex justifyContent="space-between">
            <Heading size="lg" mb="6">
              {t('title')}
            </Heading>
            <ButtonGroup>
              <Button onClick={onMultipleOpen}>Create multiple</Button>
              <Button onClick={onOpen}>Create new</Button>
            </ButtonGroup>
          </Flex>
          {!loading ? (
            <ApplicationsTable
              onApplicationEdit={onApplicationEdit}
              handleDelete={handleDeleteApplication}
              onCoverLetterSelect={onCoverLetterSelect}
              onResumeSelect={onResumeSelect}
              handleStatusUpdate={handleStatusUpdate}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </Box>
      <CreateApplicationModal
        isOpen={isOpen}
        onClose={() => {
          setSearchParams({});
          onClose();
        }}
        isLoading={createApplicationLoading || updateApplicationLoading}
        onSubmit={handleSubmit}
        application={application}
        // onChange={handleChange}
      />
      <ParseUrlsModal
        onClose={onMultipleClose}
        isOpen={isMultipleOpen}
        handleCreateApplications={handleCreateApplications}
      />
    </Box>
  );
};

export default ApplicationsPage;
