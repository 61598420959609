import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  isLoginForm?: boolean;
  isResetPasswordForm?: boolean;
  isRepeat?: boolean;
  recoveryPasswordButton?: React.ReactElement;
}

export const PasswordField: React.FC<IProps> = React.memo(
  ({
    isLoginForm,
    isResetPasswordForm,
    isRepeat,
    recoveryPasswordButton,
    ...props
  }) => {
    const { t } = useTranslation('login');
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
        input.focus({ preventScroll: true });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };
    let formId: string = 'password';
    if (isResetPasswordForm) {
      if (isRepeat) {
        formId = 'repeatNewPassword';
      } else {
        formId = 'newPassword';
      }
    }

    let formPlaceholder: string = t('form.password.placeholder');
    if (isResetPasswordForm) {
      if (isRepeat) {
        formPlaceholder = t('form.password.repeat');
      } else {
        formPlaceholder = t('form.password.new');
      }
    }

    return (
      <Field name={formId}>
        {({ field, form }: FieldProps) => (
          <FormControl id={formId} tabIndex={isLoginForm ? -1 : 3}>
            <Flex justify="space-between">
              <FormLabel my="auto">{formPlaceholder}</FormLabel>
              {recoveryPasswordButton}
            </Flex>
            <InputGroup>
              <InputRightElement>
                <IconButton
                  bg="transparent !important"
                  variant="ghost"
                  aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                  onClick={onClickReveal}
                  icon={isOpen ? <ViewOffIcon /> : <ViewIcon />}
                  tabIndex={-1}
                />
              </InputRightElement>
              <Input
                ref={inputRef}
                type={isOpen ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder={formPlaceholder}
                isInvalid={!!form.errors[formId]}
                required
                {...field}
                {...props}
                tabIndex={isLoginForm ? 4 : 0}
              />
            </InputGroup>
            <Box
              width={'100%'}
              color="red.500"
              sx={{ '&::first-letter': { textTransform: 'capitalize' } }}
            >
              {form.errors.formId as string}
            </Box>
          </FormControl>
        )}
      </Field>
    );
  },
);

PasswordField.displayName = 'PasswordField';
