import { ApolloQueryResult, OperationVariables } from '@apollo/client';

export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum Plan {
  FREE = 'free',
  STANDARD = 'standard_monthly',
  LIFE_TIME_DEAL = 'ltd',
}

export enum EVersionTag {
  AUTOSAVE = 'AUTOSAVE',
  AI_REWRITE = 'AI_REWRITE',
  LANGUAGE_CHANGE = 'LANGUAGE_CHANGE',
}

export enum ECVLanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH',
}

export type TVersion = {
  id: string;
  createdAt: string;
  content: string;
  tag: EVersionTag;
};

export interface IUser {
  id: string;
  linkedInId: string;
  email: string;
  firstname: string;
  lastname: string;
  plan: Plan;
  resumes: IResume;
  createdAt: Date;
  updatedAt: Date;
  role: Role;
  isMainTourPassed: boolean;
  isProfileTourPassed: boolean;
  isCvTourPassed: boolean;
}

export interface IResume {
  author: IUser;
  createdAt: string;
  id: string;
  updatedAt: Date;
  html?: string;
  pdf?: string;
  published: boolean;
  title?: string;
  content?: string;
  job?: string;
  description?: string;
  version: TVersion[];
  isDefault: boolean;
  profile: TProfile;
  language: ECVLanguage;
}

export interface IRecommender {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  currentCompany: string;
  currentJobTitle: string;
}

export interface IRecommendation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  content: string;
  company: string;
  recommendeeJobTitle: string;
  recommenderJobTitle: string;
  published: boolean;
  author: IUser;
  resume: IResume;
  recommender: IRecommender;
  isApproved: boolean;
}

export interface ICoverLetter {
  id: string;
  createdAt: string;
  updatedAt: Date;
  title: string;
  content: string;
  application: IApplication;
  author: IUser;
}

export enum EApplicationStatus {
  CREATED = 'CREATED',
  APPLIED = 'APPLIED',
  INVITED = 'INVITED',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}
export interface IApplication {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description?: string;
  url?: string;
  company?: string;
  postDate: Date | null;
  validDate: Date | null;
  isActive?: boolean;
  coverLetter?: ICoverLetter;
  resume: IResume;
  user: IUser;
  status: EApplicationStatus;
}

export type TProfile = {
  id: string;
  name: string;
  content: string;
};

export type TInitialCVData = {
  title: string;
  language: ECVLanguage;
  company: string;
  industry: string;
  published: boolean;
  job: string;
  description: string;
  profileId: string;
};

export interface IPaginatedProps<T> {
  loading?: Boolean;
  endCursor?: string;
  startCursor?: string;
  hasNextPage?: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<any>>;
  data?: {
    node: T;
  }[];
}

export type TPaginated<T> = {
  edges: Array<{ node: T }>;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: number;
    endCursor: number;
  };
  totalCount: number;
};
