import React from 'react';

import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import enFlag from '../../assets/images/gb.svg';
import esFlag from '../../assets/images/es.svg';
import frFlag from '../../assets/images/fr.svg';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC<{ isNavbarOpen?: boolean }> = ({
  isNavbarOpen,
}) => {
  const { t, i18n } = useTranslation('navbar');

  const languages = [
    {
      locale: 'en',
      name: t('language.english'),
      flag: enFlag,
    },
    {
      locale: 'es',
      name: t('language.spanish'),
      flag: esFlag,
    },
    {
      locale: 'fr',
      name: t('language.french'),
      flag: frFlag,
    },
  ];

  const currentLocale =
    languages.find((lng) => lng.locale === i18n.language) || languages[0];

  return (
    <Menu>
      <MenuButton
        // as={Button}
        bg={isNavbarOpen ? 'blackAlpha.400' : 'transparent'}
        _hover={{
          bg: !isNavbarOpen && 'whiteAlpha.200',
        }}
        display="flex"
      >
        <Flex py={2} px={4} color="white" alignItems="center" fontWeight={500}>
          <Image
            boxSize="2rem"
            borderRadius="lg"
            src={currentLocale.flag}
            alt={currentLocale.name}
            mr={isNavbarOpen ? 3 : 0}
          />
          <Text
            as="span"
            display={isNavbarOpen ? 'block' : 'none'}
            opacity={isNavbarOpen ? 1 : 0}
            visibility={isNavbarOpen ? 'visible' : 'hidden'}
            textTransform="capitalize"
          >
            {currentLocale.name}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList color="black">
        {languages.map(({ locale, name, flag }) => (
          <MenuItem
            key={locale}
            minH="48px"
            onClick={() => i18n.changeLanguage(locale)}
          >
            <Image
              boxSize="2rem"
              borderRadius="lg"
              src={flag}
              alt={name}
              mr="12px"
            />
            <Text as="span" textTransform="capitalize">
              {name}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitcher;
