import React, { ReactElement } from 'react';

import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

type TMenuItem = {
  id: number | string;
  onClickHandler: () => void;
  icon?: ReactElement<any, any>;
  text: string;
  disabled?: boolean;
};

interface Props {
  menuItems: Array<TMenuItem | ReactElement>;
}

const PageMenu: React.FC<Props> = ({ menuItems }) => {
  const { t } = useTranslation('cv');

  return menuItems.length ? (
    <Box
      id="actions-button"
      pos="absolute"
      top={{ base: 3, lg: 2.5 }}
      right={4}
      zIndex={100}
    >
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size={{
                base: 'sm',
                lg: 'md',
              }}
            >
              {t('actions.title')}
            </MenuButton>
            <MenuList id="actions-menu">
              {menuItems.map((item, index) => {
                if (Object(item).hasOwnProperty('id')) {
                  const { id, onClickHandler, icon, text, disabled } =
                    item as TMenuItem;
                  return (
                    <MenuItem
                      key={id}
                      icon={icon}
                      onClick={onClickHandler}
                      disabled={disabled}
                      opacity={disabled ? 0.4 : 1}
                      cursor={disabled ? 'not-allowed' : 'pointer'}
                    >
                      {text}
                    </MenuItem>
                  );
                }
                return <Box key={index}>{item as ReactElement}</Box>;
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  ) : null;
};

export default PageMenu;
