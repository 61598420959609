import React from 'react';

import 'driver.js/dist/driver.css';
import { Box, Heading } from '@chakra-ui/react';
import TablePagination from '../../components/MyCVsPage/TablePagination';
import { useQuery } from '@apollo/client';
import { FIND_ALL_RECOMMENDATIONS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import TableContent from './TableContent';

const RecommendationsPage: React.FC = () => {
  const { t } = useTranslation('recommendations');
  const { data, loading, refetch } = useQuery(FIND_ALL_RECOMMENDATIONS, {
    variables: { first: 6 },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Box as="section" py="1">
      <Box maxW={{ base: 'xl', md: '7xl' }}>
        <Box w={'100%'}>
          <Heading size="lg" mb="6">
            {t('title')}
          </Heading>
          {/* TODO Update table actions */}
          {/* <TableActions refetch={refetch} /> */}
          <TableContent
            data={data?.findAllRecommendations?.edges}
            refetch={refetch}
            loading={loading}
          />
          <TablePagination
            hasPreviousPage={
              data?.findAllRecommendations?.pageInfo?.hasPreviousPage
            }
            hasNextPage={data?.findAllRecommendations?.pageInfo?.hasNextPage}
            total={data?.findAllRecommendations?.totalCount}
            countString={`${data?.findAllRecommendations?.totalCount ?? 0} ${t(
              'name',
            )}`}
            startCursor={data?.findAllRecommendations?.pageInfo?.startCursor}
            endCursor={data?.findAllRecommendations?.pageInfo?.endCursor}
            loading={loading}
            refetch={refetch}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendationsPage;
