import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { useTranslation } from 'react-i18next';

interface IProps {
  total: number;
  countString: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
  startCursor: string;
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<any>>;
}

const TablePagination: React.FC<IProps> = React.memo(
  ({
    total,
    countString,
    hasNextPage,
    hasPreviousPage,
    endCursor,
    startCursor,
    loading,
    refetch,
  }) => {
    const { t } = useTranslation('myCVs');
    const handleNext = async () => {
      await refetch({ after: endCursor, first: 6, last: null, before: null });
    };
    const handlePrev = async () => {
      await refetch({ before: startCursor, last: 6, first: null, after: null });
    };

    return (
      <Flex align="center" justify="space-between">
        <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
          {countString}
        </Text>
        {total > 6 ? (
          <ButtonGroup variant="outline" size="sm">
            <Button
              rel="prev"
              isDisabled={loading || !hasPreviousPage}
              onClick={handlePrev}
            >
              {t('table.previous')}
            </Button>
            <Button
              rel="next"
              isDisabled={loading || !hasNextPage}
              onClick={handleNext}
            >
              {t('table.next')}
            </Button>
          </ButtonGroup>
        ) : null}
      </Flex>
    );
  },
);

export default TablePagination;
