import React from 'react';

import 'driver.js/dist/driver.css';
import { Box } from '@chakra-ui/react';
import { useAuth } from 'src/components/AuthProvider/AuthProvider';
import { useMainPageTour } from 'src/hooks';
import useAppStore from 'src/store';
import CVsTable from './CVsTable';
import { ApolloError, useMutation } from '@apollo/client';
import { useAppToast } from 'src/hooks/useAppToast';
import { DELETE_RESUME } from 'src/graphql/mutations/deleteResume';
import { SET_RESUME_DEFAULT } from 'src/graphql/mutations';

const MyCvsPage: React.FC = () => {
  const { user } = useAuth();
  const { mainPageTour } = useMainPageTour();
  const initResumes = useAppStore((state) => state.initResumes);
  const resumes = useAppStore((state) => state.resumes);
  const setResumes = useAppStore((state) => state.setResumes);
  const { showToast, closeToast } = useAppToast('myCVsPage');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };
  const [deleteResumeMutation] = useMutation(DELETE_RESUME, {
    onCompleted: (data, options) => {
      if (data.removeResume) {
        setResumes(resumes.filter((cv) => cv.id !== options?.variables?.id));
        showToast({
          content: 'CV succesfully removed',
          status: 'success',
        });
      }
    },
    onError,
  });
  const [setDefaultResume] = useMutation(SET_RESUME_DEFAULT, {
    onCompleted: (data, options) => {
      if (data.updateResume.isDefault) {
        setResumes(
          resumes.map((cv) =>
            cv.id !== options?.variables?.id
              ? { ...cv, isDefault: false }
              : { ...cv, isDefault: true },
          ),
        );
        showToast({
          content: 'Default resume successfully updated',
          status: 'success',
        });
      }
    },
    onError,
  });

  const handleDeleteResume = async (id: string) => {
    await deleteResumeMutation({ variables: { id } });
  };

  React.useEffect(() => {
    if (user && !user.isMainTourPassed) {
      mainPageTour.drive();
    }
  }, [user, mainPageTour]);

  React.useEffect(() => {
    initResumes();
  }, [initResumes]);

  return (
    <Box as="section" py="1">
      <CVsTable
        setAsDefault={(id) =>
          setDefaultResume({
            variables: {
              id,
            },
          })
        }
        handleDelete={handleDeleteResume}
      />
    </Box>
  );
};

export default MyCvsPage;
