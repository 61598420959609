import { gql } from '@apollo/client';

export const FIND_ALL_COVER_LETTERS = gql`
  query findAllCoverLetters(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $query: String!
  ) {
    findAllCoverLetters(
      first: $first
      after: $after
      last: $last
      before: $before
      query: $query
    ) {
      edges {
        node {
          application {
            id
            title
          }
          author {
            id
            firstname
          }
          content
          createdAt
          id
          title
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
