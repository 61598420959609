import React from 'react';
import { useAuth } from '../AuthProvider/AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';

const withAuth: any = (Component: React.FC) => (props: any) => {
  const { isAuth } = useAuth();
  const location = useLocation();

  return (
    <>
      {isAuth ||
      location.pathname === '/auth' ||
      location.pathname === '/recommendation-letter' ||
      location.pathname === '/auth/password_recovery' ||
      location.pathname === '/auth/linkedin' ? (
        <Component {...props} />
      ) : (
        <Navigate to={'/auth'} />
      )}
    </>
  );
};

export default withAuth;
