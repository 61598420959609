import React, { useEffect } from 'react';

import useAppStore from 'src/store';
import AppModal from '../AppModal/AppModal';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useCreateResume } from 'src/hooks/useCreateResume';
import { templates } from 'src/constants';
import TemplateCollection from '../TemplateCollection/TemplateCollection';
import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Heading,
  Select,
  Stack,
} from '@chakra-ui/react';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import { useTranslation } from 'react-i18next';
import { ECVLanguage } from 'src/types/types';
import { ECreateModalStep, TInitialCreateState } from 'src/store/slices';

type CVFormField = {
  label: string;
  name: string;
  placeholder?: string;
  type: 'textarea' | 'input' | 'select';
};

const CreateCVModal = () => {
  const { t } = useTranslation('createCV');
  const { createResume, loading } = useCreateResume();
  const isModalOpen = useAppStore((state) => state.isCreateModalOpen);
  const createModalStep = useAppStore((state) => state.createModalStep);
  const setCreateModalStep = useAppStore((state) => state.setCreateModalStep);
  const setModalOpen = useAppStore((state) => state.setCreateModalState);
  const initialCreateState = useAppStore((state) => state.initialCreateState);
  const setInitialCreateState = useAppStore(
    (state) => state.setInitialCreateState,
  );
  const handleClose = () => {
    setCreateModalStep(ECreateModalStep.SELECT_TEMPLATE);
    setModalOpen(false);
  };

  const languageOptions = Object.values(ECVLanguage).map((option) => ({
    label: option
      .split('')
      .map((l, i) => (i !== 0 ? l.toLowerCase() : l))
      .join(''),
    value: ECVLanguage[option],
  }));

  const createCVFormFields: CVFormField[] = [
    {
      label: t('options.language.label'),
      name: 'language',
      type: 'select',
    },
    {
      label: t('options.titleField.label'),
      name: 'title',
      placeholder: t('options.titleField.placeholder'),
      type: 'input',
    },
    {
      label: t('options.industry.title'),
      name: 'industry',
      placeholder: t('options.industry.placeholder'),
      type: 'input',
    },
    {
      label: t('options.company.title'),
      name: 'company',
      placeholder: t('options.company.placeholder'),
      type: 'input',
    },
    {
      label: t('options.job.title'),
      name: 'job',
      placeholder: t('options.job.placeholder'),
      type: 'input',
    },
    {
      label: t('options.description.title'),
      name: 'description',
      placeholder: t('options.description.placeholder'),
      type: 'textarea',
    },
  ];

  useEffect(() => {
    switch (localStorage.getItem('i18nextLng')) {
      case 'es':
        setInitialCreateState({ language: ECVLanguage.SPANISH });
        break;
      case 'fr':
        setInitialCreateState({ language: ECVLanguage.FRENCH });
        break;
      default:
        setInitialCreateState({ language: ECVLanguage.ENGLISH });
    }
  }, [setInitialCreateState]);

  return (
    <AppModal isOpen={isModalOpen} onClose={handleClose} size="3xl">
      <Formik
        initialValues={initialCreateState}
        onSubmit={async (values) => {
          await createResume(values);
          setCreateModalStep(ECreateModalStep.SELECT_TEMPLATE);
          setModalOpen(false);
        }}
        // enableReinitialize
      >
        {(props: FormikProps<TInitialCreateState>) => (
          <Form>
            {createModalStep === ECreateModalStep.SELECT_TEMPLATE ? (
              <TemplateCollection
                height={'500px'}
                css={props.values.css}
                templates={templates}
              />
            ) : (
              <Box>
                <Heading size="md" mb={4}>
                  {t('options.title')}
                </Heading>
                <Stack my={8} gap={2} maxW="lg" mx="auto">
                  {createCVFormFields.map(
                    ({ label, name, placeholder, type }) =>
                      type !== 'select' ? (
                        <Field
                          key={name}
                          name={name}
                          component={ValidatedInput}
                          placeholder={placeholder}
                          textarea={type === 'textarea'}
                          label={
                            <FormLabel fontSize={{ base: 'sm', lg: 'md' }}>
                              {label}
                            </FormLabel>
                          }
                        />
                      ) : (
                        <>
                          <FormLabel fontSize={{ base: 'sm', lg: 'md' }} mb={0}>
                            {label}
                          </FormLabel>
                          <Field
                            id={name}
                            key={name}
                            name={name}
                            as="select"
                            component={Select}
                            defaultValue={props.values.language}
                            onChange={props.handleChange}
                          >
                            {languageOptions.map((option) => (
                              <option key={option.label} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                        </>
                      ),
                  )}
                </Stack>
              </Box>
            )}
            <ButtonGroup pt={4} pb={2} display="flex" justifyContent="end">
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                id="choose-template"
                colorScheme={
                  createModalStep === ECreateModalStep.SELECT_TEMPLATE
                    ? 'blue'
                    : 'green'
                }
                onClick={() =>
                  createModalStep === ECreateModalStep.SELECT_TEMPLATE
                    ? setCreateModalStep(ECreateModalStep.SELECT_VALUES)
                    : props.handleSubmit()
                }
                isLoading={loading}
              >
                {createModalStep === ECreateModalStep.SELECT_TEMPLATE
                  ? t('choose')
                  : t('create')}
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </AppModal>
  );
};

export default CreateCVModal;
