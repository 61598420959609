import React, { useEffect } from 'react';
import {
  Box,
  Center,
  Flex,
  FlexProps,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SmallAddIcon } from '@chakra-ui/icons';
import NavLink from './Navlink';
import NavHeading from './NavHeading';
import { HiTemplate } from 'react-icons/hi';
import {
  MdOutlineAttachMoney,
  MdSupervisorAccount,
  MdManageAccounts,
  MdFolder,
  MdAssignment,
  MdContactPage,
  MdMail,
} from 'react-icons/md';
import Logo from '../Logo/Logo';
import { useAuth } from '../AuthProvider/AuthProvider';
import AccountSwitcher from './AccountSwitcher';
import { useCreateResume } from '../../hooks/useCreateResume';
import NavItem from './NavItem';
import styled from '@emotion/styled';
import { useAppToast } from 'src/hooks/useAppToast';
import useAppStore from 'src/store';
import SmallLogo from '../Logo/SmallLogo';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const StyledNavLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isDisabled',
})<{
  isOpen?: boolean;
  isDisabled?: boolean;
}>(
  ({ isOpen, isDisabled }) => `
  & {
    cursor: ${isDisabled ? 'default' : 'pointer'};
  }
  & > div > div {
    width: 100%;
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
      // margin-left: ${isOpen ? 0 : '.8rem'};
      color: ${isDisabled ? 'gray' : 'white'};
      // margin-right: auto;
      // transition: width 500ms ease-in-out;
      // transition: margin-left 500ms ease-in-out;
    }
    & > span {
      width: 100%;
      display: ${isOpen ? 'flex' : 'none'};
      visibility: ${isOpen ? 'visible' : 'hidden'};
      opacity: ${isOpen ? '1' : '0'};
      transition: opacity 500ms ease-in-out;
      & > span {
        font-size: 0.75rem;
        color: gray;
        margin-left: auto;
      }
    }
  }
`,
);

const StyledNavItem = styled(NavItem, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(
  ({ isOpen }: { isOpen?: boolean }) => `
  & > div > div {
    width: 100%;
    & > svg {
      width: 1.5rem;
      height: 1.5rem;
      // margin-left: ${isOpen ? 0 : '.8rem'};
      // margin-right: auto;
      // transition: width 500ms ease-in-out;
      // transition: margin-left 500ms ease-in-out;
    }
    & > span {
      width: 100%;
      display: ${isOpen ? 'flex' : 'none'};
      visibility: ${isOpen ? 'visible' : 'hidden'};
      opacity: ${isOpen ? '1' : '0'};
      transition: opacity 500ms ease-in-out;
      & > span {
        font-size: 0.75rem;
        color: gray;
        margin-left: auto;
      }
    }
  }
`,
);

const Navbar: React.FC<FlexProps & { isOpen?: boolean; onClose?: () => void }> =
  React.memo(({ isOpen, onClose, ...props }) => {
    const { t } = useTranslation('navbar');
    const { t: commonT } = useTranslation('common');
    const { isAuth, user } = useAuth();
    const { loading } = useCreateResume();
    const { showToast, closeToast } = useAppToast('createResumeLoading');
    const setModalOpen = useAppStore((state) => state.setCreateModalState);
    const documentsNavLinks = [
      {
        key: 4,
        icon: MdAssignment,
        path: '/applications',
        onClick: onClose,
        isOpen,
        text: t('applications'),
      },
      {
        key: 1,
        icon: MdFolder,
        path: '/my-cvs',
        onClick: onClose,
        isOpen,
        text: t('allCVs'),
      },
      {
        key: 3,
        icon: MdMail,
        path: '/cover-letters',
        onClick: onClose,
        isOpen,
        text: t('coverLetters'),
      },
      {
        key: 2,
        icon: MdContactPage,
        path: '/recommendations',
        onClick: onClose,
        isOpen,
        text: t('recommendations'),
      },
    ];

    useEffect(() => {
      if (loading) {
        showToast({
          content: 'Create resume in progress',
          status: 'loading',
        });
      } else {
        closeToast();
      }
    }, [closeToast, loading, showToast]);

    return (
      <Flex
        as="nav"
        height="100vh"
        direction="column"
        justify="space-between"
        maxW="18rem"
        width="full"
        borderRightWidth="1px"
        bg={'gray.900'}
        color={'white'}
        {...props}
      >
        <Stack id="desktop-menu" spacing="3">
          <Flex
            justifyContent="center"
            alignItems="center"
            h="31px"
            maxW="xs"
            py={8}
          >
            {isOpen ? (
              <Logo iconColor={'white'} />
            ) : (
              <SmallLogo iconColor={'white'} />
            )}
          </Flex>
          {isAuth && !!user ? (
            <Box id="menu-account" px={isOpen ? 5 : 4} pt={'3'}>
              <AccountSwitcher
                lastname={user.lastname}
                firstname={user.firstname}
                id={user.id}
                email={user.email}
                isOpen={isOpen}
              />
            </Box>
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}

          <Stack px="3" spacing="6">
            <Stack spacing="1" />
            <Stack spacing="3">
              <NavHeading
                opacity={isOpen ? 1 : 0}
                transition="opacity 500ms ease-in-out"
                textTransform="uppercase"
              >
                {t('documents')}
              </NavHeading>
              <Stack as="ul" spacing="1">
                <StyledNavItem
                  id="menu-create-cv"
                  icon={SmallAddIcon}
                  onClick={() => {
                    setModalOpen(true);
                    if (onClose) onClose();
                  }}
                  isOpen={isOpen}
                >
                  {t('createCV')}
                </StyledNavItem>
                {documentsNavLinks.map(
                  ({ key, icon, path, onClick, isOpen, text }) => (
                    <StyledNavLink
                      key={key}
                      icon={icon}
                      to={path}
                      onClick={onClick}
                      isOpen={isOpen}
                    >
                      {text}
                    </StyledNavLink>
                  ),
                )}
              </Stack>
            </Stack>
            <Stack spacing="3">
              <NavHeading
                opacity={isOpen ? 1 : 0}
                transition="opacity 500ms ease-in-out"
                textTransform="uppercase"
              >
                {t('settings')}
              </NavHeading>
              <Stack as="ul" spacing="1" onClick={onClose}>
                <StyledNavLink
                  isOpen={isOpen}
                  justifyContent="center"
                  icon={MdManageAccounts}
                  isDisabled
                  _hover={{
                    bg: 'none',
                  }}
                  to={'#'}
                  textTransform="capitalize"
                >
                  {t('account')}
                  <Text
                    display={isOpen ? 'flex' : 'none'}
                    visibility={isOpen ? 'visible' : 'hidden'}
                    opacity={isOpen ? 1 : 0}
                    transition="opacity 500ms ease-in-out"
                    fontSize="xs"
                    color="gray.400"
                    ml={isOpen ? 'auto' : 0}
                    textTransform="capitalize"
                  >
                    {commonT('comingSoon')}...
                  </Text>
                </StyledNavLink>
                <StyledNavLink
                  id="menu-profiles"
                  isOpen={isOpen}
                  icon={MdSupervisorAccount}
                  to={'/profiles'}
                  textTransform="capitalize"
                >
                  {t('myProfiles')}
                </StyledNavLink>
                <StyledNavLink
                  isOpen={isOpen}
                  icon={HiTemplate}
                  to={'/templates'}
                  textTransform="capitalize"
                >
                  {t('templates')}
                </StyledNavLink>
                <StyledNavLink
                  isOpen={isOpen}
                  icon={MdOutlineAttachMoney}
                  to={'/billing'}
                  textTransform="capitalize"
                >
                  {t('billing')}
                </StyledNavLink>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <LanguageSwitcher isNavbarOpen={isOpen} />
      </Flex>
    );
  });

export default Navbar;
