import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/react';

interface Props extends ModalProps {
  title?: React.ReactNode | string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const AppModal: React.FC<Props> = ({
  title,
  children,
  isOpen,
  onClose,
  actions,
  ...otherProps
}) => {
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      {...otherProps}
    >
      <ModalOverlay />
      <ModalContent id="profile-data">
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {actions && <ModalFooter>{actions}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default AppModal;
