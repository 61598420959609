import {
  useState,
  useEffect,
  ChangeEventHandler,
  ChangeEvent,
  useCallback,
} from 'react';

import { saveAs } from 'file-saver';
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  UPDATE_RESUME_PUBLISHED,
  UPDATE_RESUME_PROFILE,
  TRANSFORM_TO_PDF,
  UPDATE_RESUME,
  IMPROVE_RESUME,
  ATS_TEST,
  TRANSFORM_TO_HTML,
  DELETE_VERSION,
  UPDATE_RESUME_LANGUAGE,
  CREATE_RECOMMENDATION,
} from '../graphql/mutations';
import { DELETE_RESUME } from '../graphql/mutations/deleteResume';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_RESUME_BY_ID, PROFILES } from 'src/graphql/queries';
import {
  ECVLanguage,
  EVersionTag,
  TInitialCVData,
  TProfile,
  TVersion,
} from 'src/types/types';
import { useAppToast } from './useAppToast';
import parseISO from 'date-fns/parseISO';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

const defaultInitialData = {
  language: ECVLanguage.ENGLISH,
  title: '',
  company: '',
  industry: '',
  published: false,
  job: '',
  description: '',
  profileId: '',
};

export type RecommendationInput = {
  company: string;
  name: string;
  email: string;
  currentCompany: string;
  currentJobTitle: string;
  recommenderJobTitle: string;
  recommendeeJobTitle: string;
};

export const useCVActions = () => {
  const { t } = useTranslation('cv');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [initialData, setInitialData] =
    useState<TInitialCVData>(defaultInitialData);
  const { showToast, closeToast } = useAppToast('cv-page');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };
  const [setPublished] = useMutation(UPDATE_RESUME_PUBLISHED, {
    onCompleted: (data) => {
      const { published } = data.updateResume;
      if (typeof published === 'boolean') {
        if (published) {
          showToast({
            content: 'CV successfully published.',
            status: 'success',
          });
        } else {
          showToast({
            content: 'CV successfully unpublished.',
            status: 'success',
          });
        }
      }
    },
    onError,
  });
  const [setProfile] = useMutation(UPDATE_RESUME_PROFILE, {
    onCompleted: (data, clientOptions) => {
      setInitialData((prev) => ({
        ...prev,
        profileId: clientOptions?.variables?.profileId || prev.profileId,
      }));
    },
    onError,
  });
  const [deleteResumeMutation] = useMutation(DELETE_RESUME, { onError });
  const [updateResume] = useMutation(UPDATE_RESUME, {
    onError,
  });
  const [improveResume] = useMutation(IMPROVE_RESUME, {
    onCompleted: async () => {
      await refetch().then(({ data }) => {
        const latestVersion = getLatestVersion(data?.findOneResume?.versions);
        setCurrentVersion(latestVersion);
        setResumeVersions(data?.findOneResume?.versions);
      });
    },
    onError,
  });
  const [error, setError] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState<ECVLanguage>(
    ECVLanguage.ENGLISH,
  );
  const getLatestVersion = (versions: TVersion[]) =>
    versions.reduce((latest: TVersion, current: TVersion) => {
      const currentDate = parseISO(current.createdAt);
      const latestDate = parseISO(latest.createdAt);

      return currentDate > latestDate ? current : latest;
    }, versions[0]);
  const [transformToPdf] = useMutation(TRANSFORM_TO_PDF);
  const [currentVersion, setCurrentVersion] = useState<TVersion | null>(null);
  const [getResume, { loading: resumeLoading, refetch }] = useLazyQuery(
    GET_RESUME_BY_ID,
    {
      onCompleted: (data) => {
        if (data.findOneResume) {
          const {
            language,
            content,
            html,
            title,
            industry,
            company,
            published,
            job,
            description,
            versions,
            profile,
          } = data.findOneResume;
          setContent(content);
          setPreview(html);
          setSelectedLanguage(language);
          setInitialData({
            language,
            title,
            industry,
            company,
            published,
            job,
            description,
            profileId: profile?.id,
          });
          const latestVersion = getLatestVersion(versions);
          setCurrentVersion(() => latestVersion);
          setResumeVersions(versions);
        }
      },
      onError: (error) => setError(error.message),
    },
  );
  //   const [resumePublished, setResumePublished] = useState(initialData.published);
  const [profiles, setProfiles] = useState<TProfile[]>([]);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [, setIsRewriting] = useState<boolean>(false);
  const [content, setContent] = useState<null | string>(null);
  const [preview, setPreview] = useState<undefined | string>(undefined);
  const [resumeVersions, setResumeVersions] = useState<TVersion[]>([]);
  const [deleteVersionMutation] = useMutation(DELETE_VERSION, {
    onCompleted: (data, clientOptions) => {
      if (data.removeVersion) {
        const removeVersionId = clientOptions?.variables?.id;
        setResumeVersions((versions) => {
          const filteredVersions = versions.filter(
            (version) => version.id !== removeVersionId,
          );
          setCurrentVersion(filteredVersions[0]);

          return filteredVersions;
        });
      }
    },
    onError,
  });
  const [createRecommendationMutation] = useMutation(CREATE_RECOMMENDATION, {
    onCompleted: (data) => {
      if (data.createRecommendation) {
        navigate(`recommendation_letters/${data.createRecommendation.id}`);
      }
    },
    onError,
  });
  const [isPublished, setResumePublished] = useState(initialData.published);

  const updateResumeHandler = async (value: string, tag?: EVersionTag) => {
    if (value !== content) {
      setContent(value);
      await updateResume({ variables: { id, content: value, tag } });
      await refetch().then(({ data }) => {
        const latestVersion = getLatestVersion(data?.findOneResume?.versions);
        setCurrentVersion(latestVersion);
        setResumeVersions(data?.findOneResume?.versions);
      });
    }
  };

  const debouncedUpdate = useDebouncedCallback(async (value: string) => {
    await updateResumeHandler(value);
  }, 20000);

  const debouncedUpdateCVData = useDebouncedCallback(
    async (initialData: TInitialCVData) => {
      await updateResume({ variables: { id, ...initialData } });
    },
    1500,
  );

  const handleUpdateCVData: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target;
    setInitialData((prev) => {
      const result = { ...prev, [name]: value };
      debouncedUpdateCVData(result);

      return result;
    });
  };

  const handleVersionSelect = (versionId: string) => {
    setIsPreviewing(false);
    const version = resumeVersions.find((version) => version.id === versionId);
    if (version) setCurrentVersion(version);
  };

  const handleDeleteResume = async () => {
    await deleteResumeMutation({ variables: { id } });
    navigate('/my-cvs');
  };
  const handleCreateRecommendation = async (inputData: RecommendationInput) =>
    createRecommendationMutation({
      variables: {
        resumeId: id,
        ...inputData,
      },
    });
  const handleSetPublished = async () => {
    const { data } = await setPublished({
      variables: { id, published: !isPublished },
    });
    setResumePublished(data.updateResume.published);
  };
  const handleSelectProfile = async (
    e: ChangeEvent<HTMLSelectElement>,
    id: string,
  ) => {
    const { value: profileId } = e.target;
    if (profileId) {
      await setProfile({ variables: { id, profileId } });
    }
  };
  const handleSelectLanguage = async (
    e: ChangeEvent<HTMLSelectElement>,
    id: string,
  ) => {
    const language = e.target.value as ECVLanguage;
    setSelectedLanguage(language);
  };
  useQuery(PROFILES, {
    onCompleted: (data) => {
      setProfiles(data.profiles);
    },
    fetchPolicy: 'no-cache',
  });
  const handleSavePdf = async () => {
    const res = await transformToPdf({ variables: { id } });
    await saveAs(
      'data:application/pdf;base64,' + res.data.transformToPdf.pdf,
      `${initialData?.title || 'resume'}.pdf`,
    );
  };
  const [atsTest] = useMutation(ATS_TEST, {
    onCompleted: (data) => {
      const { isPassed, content } = data.atsTest;
      showToast({
        content: content,
        duration: 10000,
        status: !isPassed ? 'warning' : 'success',
        update: true,
        isClosable: true,
      });
    },
    onError,
  });
  const [transformToHtml, { loading: transformToHtmlLoading }] = useMutation(
    TRANSFORM_TO_HTML,
    {
      onCompleted: (data) => {
        setPreview(data.transformToHtml.html);
        closeToast();
      },
      onError,
    },
  );

  const [updateLanguage, { loading: updateLanguageLoading }] = useMutation(
    UPDATE_RESUME_LANGUAGE,
    {
      onCompleted: async (data) => {
        setInitialData((initData) => ({
          ...initData,
          language: data.updateResumeLanguage.language,
        }));
        await refetch().then(({ data }) => {
          const latestVersion = getLatestVersion(data?.findOneResume?.versions);
          setCurrentVersion(latestVersion);
          setResumeVersions(data?.findOneResume?.versions);
        });
        showToast({
          content: t('notifications.translate.success'),
          status: 'success',
          update: true,
        });
      },
      onError,
    },
  );

  const handleUpdateCVLanguage = async (id: string, language: ECVLanguage) => {
    setIsPreviewing(false);
    setIsRewriting(true);
    setCurrentVersion((prev) =>
      prev
        ? {
            ...prev,
            content: t('notifications.generate'),
          }
        : prev,
    );
    try {
      showToast({
        content: t('notifications.translate.loading'),
        status: 'loading',
        duration: null,
      });
      await updateLanguage({ variables: { id, language } });
    } catch (error) {
      closeToast();
      console.log('ERROR Translate resume', error);
      setIsRewriting(false);
    }
  };

  const handlePreview = useCallback(async () => {
    if (!isPreviewing) {
      showToast({ content: 'Waiting for a CV preview.', status: 'loading' });

      await transformToHtml({
        variables: { id, versionId: currentVersion?.id },
      });
    }
    setIsPreviewing(!isPreviewing);
  }, [currentVersion?.id, id, isPreviewing, showToast, transformToHtml]);

  const improveCVHandler = async () => {
    setIsPreviewing(false);
    setIsRewriting(true);
    setCurrentVersion((prev) =>
      prev
        ? {
            ...prev,
            content: t('notifications.generate'),
          }
        : prev,
    );
    try {
      showToast({
        content: t('notifications.improve.loading'),
        status: 'loading',
        duration: null,
      });
      const result = await improveResume({
        variables: {
          id,
          versionId: currentVersion?.id,
          content: currentVersion?.content,
        },
      });
      if (result.data?.improveResume.content && id) {
        setContent(result.data.improveResume.content);
        setInitialData((data) => ({
          ...data,
          language: result.data.improveResume.language,
        }));
        showToast({
          content: t('notifications.improve.success'),
          status: 'success',
          update: true,
        });
      }
      setIsRewriting(false);
    } catch (error) {
      closeToast();
      console.log('ERROR Improve resume', error);
      setIsRewriting(false);
    }
    setIsRewriting(false);
  };

  const handleATSTest = () => {
    showToast({
      duration: null,
      content: 'Waiting for an ATS Test results',
      status: 'loading',
    });
    atsTest({ variables: { id } });
  };

  const handleRemoveVersion = (id: string) => {
    deleteVersionMutation({ variables: { id } });
  };

  useEffect(() => {
    getResume({ variables: { id } });
    return () => {
      setInitialData(defaultInitialData);
      setContent('');
      setPreview('');
    };
  }, [getResume, id, setContent, setInitialData, setPreview]);

  useEffect(() => {
    setResumePublished(initialData.published);
  }, [initialData.published, setResumePublished]);

  return {
    profiles,
    handleDeleteResume,
    handleSetPublished,
    handleSelectProfile,
    handleSavePdf,
    isPreviewing,
    setIsPreviewing,
    handlePreview,
    getResume,
    initialData,
    setInitialData,
    content,
    setContent,
    preview,
    setPreview,
    refetch,
    resumeLoading,
    currentVersion,
    setCurrentVersion,
    isPublished,
    setResumePublished,
    improveCVHandler,
    atsTest,
    handleATSTest,
    handleCreateRecommendation,
    resumeVersions,
    setResumeVersions,
    transformToHtmlLoading,
    debouncedUpdate,
    handleUpdateCVData,
    handleVersionSelect,
    error,
    handleRemoveVersion,
    updateResumeHandler,
    handleSelectLanguage,
    selectedLanguage,
    handleUpdateCVLanguage,
    updateLanguageLoading,
  };
};
