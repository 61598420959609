import React, { ReactNode } from 'react';
import {
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Menu,
  MenuButton,
  useColorModeValue as mode,
  IconButton,
  SkeletonText,
  MenuList,
  Button,
  MenuItem,
} from '@chakra-ui/react';

import { EditIcon, HamburgerIcon } from '@chakra-ui/icons';

type Props<T> = {
  loading?: boolean;
  columns: { Header: string; accessor: string; Cell: any }[];
  data?: { node: T }[];
  onElementClick: (id: string) => void;
  emptyStateElement: ReactNode;
  mockElement: { node: T };
};

const Table = <T extends { id: string }>({
  loading,
  columns,
  data,
  onElementClick,
  emptyStateElement,
  mockElement,
}: Props<T>) => {
  return (
    <ChakraTable my="8" borderWidth="1px" fontSize="sm">
      <Thead bg={mode('gray.50', 'gray.800')}>
        <Tr>
          {columns.map((column, index) => (
            <Th px={4} scope="col" key={index}>
              {column.Header}
            </Th>
          ))}
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {loading
          ? [
              {
                ...mockElement,
              },
            ].map((elem) => (
              <Tr key={elem.node.id}>
                {columns.map((column, index) => (
                  <Td whiteSpace="pre-wrap" key={index}>
                    <SkeletonText
                      mt="4"
                      noOfLines={2}
                      spacing="4"
                      skeletonHeight="2"
                    />
                  </Td>
                ))}
              </Tr>
            ))
          : data?.map((elem) => (
              <Tr
                key={elem.node.id}
                onClick={() => onElementClick(elem.node.id)}
                cursor="pointer"
                transitionProperty="background-color"
                transitionTimingFunction="linear"
                transitionDelay="200ms"
                _hover={{
                  background: 'gray.100',
                }}
              >
                {columns.map((column, index) => {
                  const cell =
                    elem.node[column.accessor as keyof typeof elem.node];
                  const element = column.Cell(cell as never) ?? cell;
                  return (
                    <Td px={4} whiteSpace="pre-wrap" key={index}>
                      {element as React.ReactNode}
                    </Td>
                  );
                })}
                <Td textAlign="right">
                  <Menu direction={'ltr'}>
                    <MenuButton
                      as={IconButton}
                      icon={<HamburgerIcon />}
                      variant="ghost"
                      _hover={{
                        bg: 'gray.300',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <MenuList p={0}>
                      <MenuItem
                        p="0"
                        as={() => (
                          <Button
                            leftIcon={<EditIcon />}
                            variant="ghost"
                            colorScheme="blue"
                            w="100%"
                          >
                            Edit
                          </Button>
                        )}
                        onClick={() => onElementClick(elem.node.id)}
                      />
                      {/* <MenuDivider m={0} /> */}
                      {/* <MenuItem as="div" p="0">
                        <Formik
                          w={'100%'}
                          initialValues={{ title: '', css: 'template1' }}
                          onSubmit={
                            data?.length === 1
                              ? resetResumePage
                              : resetResume
                          }
                        >
                          {(
                            props: FormikProps<{
                              title: string;
                              css: string;
                            }>,
                          ) => (
                            <Form
                              style={{ width: '100%' }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <DeleteAlert
                                deleteId={resume.node.id}
                                submitCallback={props.handleSubmit}
                                button={
                                  <Button
                                    leftIcon={<DeleteIcon />}
                                    variant="ghost"
                                    colorScheme="red"
                                    w="100%"
                                  >
                                    Delete
                                  </Button>
                                }
                              />
                            </Form>
                          )}
                        </Formik>
                      </MenuItem> */}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
        {!data?.length && !loading && emptyStateElement ? (
          <Tr>
            <Td colSpan={8}>{emptyStateElement}</Td>
          </Tr>
        ) : null}
      </Tbody>
    </ChakraTable>
  );
};

export default Table;
