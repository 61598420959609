import React from 'react';
import 'easymde/dist/easymde.min.css';

import 'driver.js/dist/driver.css';
import { Box, Flex, Grid, GridItem, Spinner } from '@chakra-ui/react';
import MarkdownEditor from '../../components/MarkdownEditor.tsx/MarkdownEditor';
import { SidebarElementsContainer } from 'src/components/containers';
import { AutoCompleteField, InputField } from 'src/components/ui';
import { Formik, FormikProps } from 'formik';
import PageMenu from 'src/components/PageMenu/PageMenu';
import { useCoverLetter } from 'src/hooks';

const CoverLetterPage: React.FC = () => {
  const {
    content,
    application,
    isOpen,
    setIsOpen,
    menuItems,
    onChangeHandler,
    handleApplicationTitleChange,
    autocompleteOptions,
    recommendationLoading,
    applicationsLoading,
    handleSelectApplication,
    pageLoading,
  } = useCoverLetter();

  if (pageLoading || recommendationLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <PageMenu menuItems={menuItems} />
      <Grid
        templateColumns={{ base: '1fr', lg: '1fr 300px' }}
        gap={{ base: 0, lg: 6 }}
      >
        <GridItem
          display="flex"
          h={{ base: 'calc(100vh - 140px)', lg: 'calc(100vh - 124px)' }}
          pt={{ base: 4, lg: 0 }}
          justifyContent="flex-start"
          gap={{ base: '20px', md: '30px', xl: '40px' }}
          flexDir="column"
          flexGrow={1}
        >
          {/* {!isPreviewing ? ( */}
          <Box
            id="CV-editor"
            position="relative"
            w={{ base: 'calc(100vw - 80px)', lg: 'full' }}
            h="full"
            maxW="5xl"
            mx="auto"
          >
            <MarkdownEditor
              // value={currentVersion?.content || ''}
              value={content}
              onChange={onChangeHandler}
              // handleEditorDidMount={handleEditorDidMount}
            />
          </Box>
        </GridItem>
        <GridItem
          display={{ base: 'none', lg: 'block' }}
          h={{ base: 'calc(100vh - 240px)', lg: 'calc(100vh - 124px)' }}
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: 0,
            },
            '&::-webkit-scrollbar-track': {
              width: 0,
            },
          }}
        >
          <Formik
            initialValues={{
              title: application?.title || '',
              description: application?.description || '',
              company: application?.company || '',
            }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);
            }}
            enableReinitialize
          >
            {(props: FormikProps<any>) => (
              <Flex direction="column" gap={2} mt={2}>
                <SidebarElementsContainer
                  title="Application"
                  isOpen={isOpen.application}
                  setIsOpen={() =>
                    setIsOpen((prev) => ({
                      ...prev,
                      application: !prev.application,
                    }))
                  }
                  h={isOpen.application ? '350px' : '10'}
                  overflow="visible"
                >
                  <Flex flexDir="column" gap={2} mb={2}>
                    <AutoCompleteField
                      label="Title"
                      defaultValue={application?.title}
                      handleChange={handleApplicationTitleChange}
                      options={autocompleteOptions}
                      onSelectOption={({ item }) =>
                        handleSelectApplication(item.value)
                      }
                      isLoading={applicationsLoading}
                    />
                    {/* <InputField label="Title" name="title" isDisabled /> */}
                    <InputField
                      label="Description"
                      name="description"
                      textarea
                      isDisabled
                    />
                    <InputField label="Company" name="company" isDisabled />
                  </Flex>
                </SidebarElementsContainer>
              </Flex>
            )}
          </Formik>
        </GridItem>
      </Grid>
      {/* )} */}
    </Box>
  );
};

export default React.memo(CoverLetterPage);
