import React, { ChangeEventHandler, useEffect, useState } from 'react';

import 'driver.js/dist/driver.css';
import {
  Stack,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Button,
  useDisclosure,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import AppModal from 'src/components/AppModal/AppModal';
import { useMutation, useQuery } from '@apollo/client';
import { PROFILES } from 'src/graphql/queries';
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  UPDATE_PROFILE,
} from 'src/graphql/mutations';
import { useSearchParams } from 'react-router-dom';
import { TProfile } from 'src/types/types';
import { useProfilesPageTour } from 'src/hooks';
import { useAuth } from 'src/components/AuthProvider/AuthProvider';
import { useTranslation } from 'react-i18next';

const defaultProfileValue = { id: '', name: '', content: '' };

// const mockProfileData: TDefaultProfileType[] = [
//   {
//     id: '1',
//     name: 'Computer Science',
//     content:
//       'Dedicated Computer Science graduate with a strong foundation in algorithms, data structures, and software development. Proficient in languages like Java, Python, and C++, with hands-on experience in full-stack web development. Proven ability to tackle complex problems and a passion for innovative solutions in the tech industry.',
//   },
//   {
//     id: '2',
//     name: 'Marketing',
//     content:
//       'Results-oriented Marketing professional equipped with a comprehensive understanding of consumer behavior, market research, and digital marketing strategies. Experienced in creating impactful campaigns and analyzing ROI. Skilled in using tools like Google Analytics and social media platforms to drive brand awareness and engagement.',
//   },
//   {
//     id: '3',
//     name: 'Biology',
//     content:
//       'Detail-oriented Biology graduate with a solid grasp of cellular and molecular biology, genetics, and ecology. Proficient in laboratory techniques and data analysis. Demonstrated ability to conduct independent research and contribute to scientific discoveries. Passionate about environmental conservation and eager to apply knowledge in practical solutions.',
//   },
//   {
//     id: '4',
//     name: 'Civil Engineering',
//     content:
//       'Motivated Civil Engineering graduate with expertise in structural design, construction management, and project planning. Proficient in CAD software and experienced in conducting site inspections. Strong problem-solving skills and a commitment to sustainable and resilient infrastructure development.',
//   },
//   {
//     id: '5',
//     name: 'Psychology',
//     content:
//       'Compassionate Psychology graduate with a deep understanding of human behavior, cognitive processes, and psychological assessments. Skilled in conducting research studies and administering psychological tests. Excellent communication and active listening abilities, with a focus on providing empathetic support and improving mental well-being.',
//   },
//   {
//     id: '6',
//     name: 'Finance',
//     content:
//       'Analytically-driven Finance graduate with a solid foundation in financial analysis, investment strategies, and risk management. Proficient in financial modeling and utilizing tools like Excel and Bloomberg Terminal. Adept at interpreting market trends and making informed recommendations for optimizing investment portfolios.',
//   },
//   {
//     id: '7',
//     name: 'Frontend Developer',
//     content:
//       'Highly skilled and motivated Frontend Developer with [X years] of experience in designing and implementing innovative web applications. Proficient in HTML5, CSS3, JavaScript, and modern frontend frameworks like React and Angular. Adept at collaborating with cross-functional teams to drive project success. Strong problem-solving abilities and a passion for creating visually appealing and user-friendly interfaces.',
//   },
//   {
//     id: '8',
//     name: 'English Literature',
//     content:
//       'Passionate English Literature graduate with a keen eye for literary analysis and critical thinking. Well-versed in various literary genres and adept at crafting compelling narratives. Strong written and verbal communication skills, with a knack for storytelling and a deep appreciation for language and culture.',
//   },
// ];

const ProfilesPage = () => {
  const { t } = useTranslation('profiles');
  const { t: commonT } = useTranslation('common');
  const { user } = useAuth();
  const [initialProfiles, setInitialProfiles] = useState<TProfile[]>([]);
  const [selectedProfile, setSelectedProfile] =
    useState<TProfile>(defaultProfileValue);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  useQuery(PROFILES, {
    onCompleted(data) {
      setInitialProfiles(data.profiles);
    },
  });
  const [createProfileMutation, { loading: createProfileLoading }] =
    useMutation(CREATE_PROFILE, {
      onCompleted: (data) => {
        setInitialProfiles((prev) => [...prev, { ...data.createProfile }]);
        onClose();
      },
      onError: (error) => {
        console.log('CREATE PROFILE: ', error);
        onClose();
      },
    });

  const [updateProfileMutation, { loading: updateProfileLoading }] =
    useMutation(UPDATE_PROFILE, {
      onCompleted: (data) => {
        const updatedProfile = data.updateProfile;
        setInitialProfiles((prev) =>
          prev.map((profile) =>
            profile.id === updatedProfile.id ? { ...updatedProfile } : profile,
          ),
        );
        onClose();
      },
      onError: (error) => {
        console.log('UPDATE PROFILE: ', error);
        onClose();
      },
    });

  const [deleteProfileMutation, { loading: deleteProfileLoading }] =
    useMutation(DELETE_PROFILE);

  const { profilesPageTour } = useProfilesPageTour(createProfileLoading);

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setSelectedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (selectedProfile.name && selectedProfile.content) {
      if (selectedProfile.id) {
        await updateProfileMutation({
          variables: {
            id: selectedProfile.id,
            name: selectedProfile.name,
            content: selectedProfile.content,
          },
        });
      } else {
        await createProfileMutation({
          variables: {
            name: selectedProfile.name,
            content: selectedProfile.content,
          },
        });
      }
    }
    setSelectedProfile(defaultProfileValue);
    onClose();
  };

  const handleDeleteProfile = async () => {
    const { data } = await deleteProfileMutation({
      variables: {
        id: selectedProfile.id,
      },
    });
    if (data) {
      console.log('removedProfileData', data);
      setInitialProfiles((prev) =>
        prev.filter((profile) => profile.id !== data.removeProfile.id),
      );
    }
    setSelectedProfile(defaultProfileValue);
    onClose();
  };

  useEffect(() => {
    if (user && !user.isProfileTourPassed) {
      profilesPageTour.drive();
    }
  }, [profilesPageTour, user]);

  return (
    <Stack gap={2}>
      <Flex>
        <Heading as="h1" size="lg">
          {t('title')}
        </Heading>
        <Button
          id="create-profile-button"
          colorScheme="blue"
          onClick={() => {
            setSelectedProfile(defaultProfileValue);
            onOpen();
          }}
          ml="auto"
        >
          {t('create')}
        </Button>
      </Flex>
      <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        {initialProfiles.map(({ id, name, content }) => (
          <Flex
            key={id}
            flexDir="column"
            gap={2}
            p={4}
            borderRadius={6}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.200"
          >
            <Heading size="sm">{name}</Heading>
            <Text size="xs" noOfLines={4}>
              {content}
            </Text>
            <ButtonGroup justifyContent="end" mt="auto">
              <IconButton
                aria-label="delete-profile"
                colorScheme="red"
                size="sm"
                icon={<DeleteIcon />}
                onClick={() => {
                  setSearchParams({ profileId: id });
                  onDeleteModalOpen();
                }}
              />
              <Button
                onClick={() => {
                  const profile = initialProfiles.find(
                    (profile) => profile.id === id,
                  );

                  if (profile) {
                    setSelectedProfile(profile);
                    onOpen();
                  }
                }}
                size="sm"
                ml="auto"
                mt="auto"
              >
                {t('edit')}
              </Button>
            </ButtonGroup>
          </Flex>
        ))}
      </SimpleGrid>
      <AppModal
        title={
          <FormControl style={{ display: 'flex' }}>
            <FormLabel
              style={{
                marginBottom: '.25em',
                marginRight: '.5em',
              }}
              cursor="text"
              opacity={'.4'}
              _hover={{ opacity: '1' }}
            >
              <EditIcon />
            </FormLabel>
            <Input
              fontWeight={'bold'}
              textTransform={'capitalize'}
              fontSize={'18px'}
              variant="unstyled"
              placeholder={`${t('modal.enter')}...`}
              name="name"
              defaultValue={selectedProfile.name}
              onChange={handleChange}
              autoFocus
            />
          </FormControl>
        }
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <>
            {selectedProfile.id && (
              <Menu>
                <MenuButton>
                  <Button
                    variant="outline"
                    colorScheme="red"
                    mr={3}
                    isLoading={deleteProfileLoading}
                  >
                    {commonT('delete')}
                  </Button>
                </MenuButton>
                <MenuList minW="fit-content">
                  <MenuItem as="div" w="fit-content">
                    <Button variant="outline" onClick={onClose}>
                      {commonT('cancel')}
                    </Button>
                  </MenuItem>
                  <MenuItem as="div" w="fit-content">
                    <Button colorScheme="red" onClick={handleDeleteProfile}>
                      {commonT('confirm')}
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            <Button
              id="save-profile"
              type="submit"
              colorScheme="green"
              isLoading={createProfileLoading || updateProfileLoading}
              onClick={handleSubmit}
            >
              {commonT('save')}
            </Button>
          </>
        }
      >
        <FormControl id="decription">
          <FormLabel>{t('modal.description.label')}</FormLabel>
          <Textarea
            rows={10}
            defaultValue={selectedProfile.content}
            onChange={handleChange}
            placeholder={t('modal.description.placeholder')}
            name="content"
          />
        </FormControl>
      </AppModal>
      <AppModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setSearchParams({});
          onDeleteModalClose();
        }}
        title={t('modal.sure')}
        actions={
          <ButtonGroup justifyContent="end">
            <Button
              onClick={() => {
                setSearchParams({});
                onDeleteModalClose();
              }}
            >
              {commonT('cancel')}
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                const id = searchParams.get('profileId');
                const { data } = await deleteProfileMutation({
                  variables: {
                    id,
                  },
                });
                if (data) {
                  setInitialProfiles((prev) =>
                    prev.filter(
                      (profile) => profile.id !== data.removeProfile.id,
                    ),
                  );
                  onDeleteModalClose();
                }
              }}
              isLoading={deleteProfileLoading}
            >
              {commonT('confirm')}
            </Button>
          </ButtonGroup>
        }
        children={<div />}
      />
    </Stack>
  );
};

export default ProfilesPage;
