import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FIND_RESUME_RECOMMENDATIONS } from 'src/graphql/queries';
import { IRecommendation } from 'src/types/types';

export const useRecommendations = () => {
  const { id } = useParams();
  const [cvRecommendations, setCvRecommendations] = useState<IRecommendation[]>(
    [],
  );
  const [getRecommendations] = useLazyQuery(FIND_RESUME_RECOMMENDATIONS, {
    variables: {
      resumeId: id,
    },
    onCompleted: (data) => {
      if (data?.findResumeRecommendations) {
        setCvRecommendations(data.findResumeRecommendations);
      }
    },
  });

  useEffect(() => {
    if (id) {
      getRecommendations();
    }
  }, [getRecommendations, id]);

  return { cvRecommendations };
};
