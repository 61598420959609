import React from 'react';
import { Badge, Heading, Text, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IPaginatedProps, IRecommendation, IResume } from '../../types/types';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enGB, fr, es } from 'date-fns/locale';
import Table from 'src/components/Table/Table';

const mockRecommendation: {
  node: Partial<IRecommendation> & {
    profile: any;
    industry: string;
    company: string;
  };
} = {
  node: {
    // createdAt: '2023-09-28T13:39:33.034Z',
    id: 'cln3823hm0035tiuvwbyi823m',
    published: false,
    title: 'title',
    profile: {
      id: '123',
      name: 'name',
    },
    industry: 'industry',
    company: 'company',
  },
};

const TableContent: React.FC<IPaginatedProps<IRecommendation>> = React.memo(
  ({ loading, data, refetch }) => {
    const { t, i18n } = useTranslation('recommendations');
    const dateLocale: Record<string, Locale> = {
      en: enGB,
      es: es,
      fr: fr,
    };
    // const setModalOpen = useAppStore((state) => state.setCreateModalState);
    const nav = useNavigate();
    const columns = [
      {
        Header: t('table.title'),
        accessor: 'title',
        Cell: function getUser(title?: string) {
          return !!title ? title : 'Untitled';
        },
      },
      {
        Header: 'resume',
        accessor: 'resume',
        Cell: function getResume(resume: IResume) {
          return resume?.title || '';
        },
      },
      {
        Header: t('table.status'),
        accessor: 'isApproved',
        Cell: function getApproved(isApproved: boolean) {
          return (
            <>
              {isApproved ? (
                <Badge
                  textTransform="uppercase"
                  fontSize="xs"
                  colorScheme={'green'}
                >
                  Approved
                </Badge>
              ) : (
                <Badge
                  textTransform="uppercase"
                  fontSize="xs"
                  colorScheme={'gray'}
                >
                  {t('filterByCv.draft')}
                </Badge>
              )}
            </>
          );
        },
      },
      {
        Header: t('table.lastUpdate'),
        accessor: 'updatedAt',
        Cell: function getLastUpdated(lastUpdate: Date) {
          return formatDistance(new Date(lastUpdate), Date.now(), {
            includeSeconds: true,
            addSuffix: true,
            locale: dateLocale[i18n.language] ?? enGB,
          });
        },
      },
      {
        Header: 'Context company',
        accessor: 'company',
        Cell: function getCompany(company?: string) {
          return company || '';
        },
      },
      {
        Header: 'Recomender name',
        accessor: 'recommender',
        Cell: function getRecommenderName(recommender: any) {
          return recommender?.name ?? '';
        },
      },
    ];

    const emptyState = (
      <Flex flexDir="column" justifyContent="center">
        <Heading size="sm" textAlign="center" py={2}>
          No Recommendation Letters
        </Heading>
        <Text textAlign="center">
          Get started by creating a new CV and recommendation letter for them.
        </Text>
        {/* <Button maxW="xs" mx="auto" my={3} onClick={console.log}>
          Create a new recommendation letter
        </Button> */}
      </Flex>
    );

    return (
      <Table<IRecommendation>
        loading={loading as boolean}
        columns={columns}
        data={data}
        onElementClick={(id) => nav(`/recommendations/${id}`)}
        emptyStateElement={emptyState}
        // @ts-ignore
        mockElement={mockRecommendation}
      />
    );
  },
);

export default TableContent;
