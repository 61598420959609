import React, { useState } from 'react';

import {
  Stack,
  Button,
  FormLabel,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import AppModal from 'src/components/AppModal/AppModal';

const ParseUrlsModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  handleCreateApplications: (urls: string) => void;
}> = ({ isOpen, onClose, handleCreateApplications }) => {
  const [urls, setUrls] = useState('');

  return (
    <AppModal
      title="Parse applications data"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setUrls('');
      }}
      actions={
        <Button
          id="save-profile"
          type="submit"
          colorScheme="green"
          onClick={() => handleCreateApplications(urls)}
        >
          Add applications
        </Button>
      }
    >
      <Stack gap={2}>
        <FormControl id="decription">
          <FormLabel>URLs</FormLabel>
          <Textarea
            rows={10}
            defaultValue={urls}
            onChange={(e) => {
              const { value } = e.target;
              setUrls(value);
            }}
            placeholder="Paste your URLs with any separator to parse applications data and add multiple applications"
            name="description"
          />
        </FormControl>
      </Stack>
    </AppModal>
  );
};

export default React.memo(ParseUrlsModal);
