import { gql } from '@apollo/client';

export const UPDATE_RESUME = gql`
  mutation updateResume(
    $id: ID!
    $content: String
    $title: String
    $industry: String
    $company: String
    $job: String
    $description: String
    $tag: String
  ) {
    updateResume(
      id: $id
      data: {
        content: $content
        title: $title
        industry: $industry
        company: $company
        job: $job
        description: $description
        tag: $tag
      }
    ) {
      id
      content
      title
      industry
      company
      job
      description
    }
  }
`;

export const UPDATE_RESUME_PUBLISHED = gql`
  mutation updateResume($id: ID!, $published: Boolean) {
    updateResume(id: $id, data: { published: $published }) {
      published
    }
  }
`;

export const UPDATE_RESUME_PROFILE = gql`
  mutation updateResume($id: ID!, $profileId: String) {
    updateResume(id: $id, data: { profileId: $profileId }) {
      profile {
        id
      }
    }
  }
`;

export const SET_RESUME_DEFAULT = gql`
  mutation updateResume($id: ID!) {
    updateResume(id: $id, data: { isDefault: true }) {
      isDefault
    }
  }
`;
