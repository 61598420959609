import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter } from 'react-router-dom';
import { client } from './graphql/client';
import { ApolloProvider } from '@apollo/client';
import AuthProvider from './components/AuthProvider/AuthProvider';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/studio-dev\.cvista\.app/,
          /^https:\/\/studio\.cvista\.app/,
        ],
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.REACT_APP_BRANCH,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.captureMessage('INIT APP');
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n} defaultNS={'main'}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ChakraProvider resetCSS>
            <App />
          </ChakraProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  </I18nextProvider>,
  // </React.StrictMode>,
);

reportWebVitals();
